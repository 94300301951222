<template>
  <div>
    <m-page-title title="My Profile" />
    <b-alert v-model="userProfileSaveAlert" :variant="userProfileSaveAlertVariant" dismissible>{{ userProfileSaveAlertMessage }}</b-alert>
    <b-row class="my-2"
      ><b-colxx md="3" sm="12">
        <b-form-group
          id="select-default-to-do-list"
          label="Select Default To Do List"
          label-for="default-to-do-list"
          class="field-label-form-group mt-2"
        >
          <b-form-select
            id="default-to-do-list"
            v-model="selectDefaultToDoList"
            :options="outlookToDoListOptions"
            value-field="id"
            text-field="displayName"
          ></b-form-select>
        </b-form-group> </b-colxx
    ></b-row>
    <b-row
      ><b-colxx md="2" sm="12">
        <b-button variant="success" @click="saveUserProfile()">Save</b-button>
      </b-colxx></b-row
    >
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import django_session from "@/api/django_session";
  export default {
    name: "MyProfile",
    components: {},

    data() {
      return {
        userProfile: {},
        userProfileSaveAlert: false,
        userProfileSaveAlertMessage: "",
        userProfileSaveAlertVariant: "",

        selectDefaultToDoList: "",
      };
    },

    computed: {
      ...mapGetters("auth", ["user", "outlookToDoListOptions"]),
    },

    created() {},

    mounted() {
      this.userProfile = this.user;
      this.selectDefaultToDoList = this.user.default_to_do_list;
      document.title = "My Profile";
    },

    methods: {
      saveUserProfile() {
        django_session
          .patch("erp/user/" + this.user.user_id + "/", {
            refresh_token_only: false,
            update_self: true,
            first_name: this.userProfile.first_name,
            last_name: this.userProfile.last_name,
            phone: this.userProfile.phone,
            default_to_do_list: this.selectDefaultToDoList,
          })
          .then((response) => {
            console.log(response.data);
            this.$store.dispatch("auth/updateUser", response.data);
            this.userProfileSaveAlertMessage = "User profile has been updated."
            this.userProfileSaveAlertVariant = "success"
            this.userProfileSaveAlert = true
          }).catch((error) => {
            this.userProfileSaveAlertMessage = "There was a problem updating your profile. " + error.response.data
            this.userProfileSaveAlertVariant = "warning"
            this.userProfileSaveAlert = true
          })
      },
    },
  };
</script>
