import django_session from "@/api/django_session"
import store from "@/store"

export const saveActivity = async (activityData, editActivityYN) => {
  let reminderUTC = null
  let dueDateUTC = null
  if (activityData.due_date !== null) {
    dueDateUTC = new Date(activityData.due_date).toISOString()
  }
  if (activityData.reminder_date !== null) {
    reminderUTC = new Date(activityData.reminder_date).toISOString()
  }

  let activity = undefined

  if (editActivityYN) {
    await django_session
      .patch("erp/activity/" + activityData.activity_id + "/", {
        assigned_to: activityData.assigned_to,
        detail: activityData.detail,
        due_date: dueDateUTC,
        entity_type: activityData.entity_type,
        modified_by: store.state.auth.user.user_id,
        owning_entity_id: activityData.owning_entity_id,
        related_outlook_task_id: activityData.related_outlook_task_id,
        reminder_date: reminderUTC,
        status: activityData.status,
        subject: activityData.subject,
      })
      .then((response) => {
        activity = response
      })
      .catch((error) => {
        activity = error.response
      })
  }

  return activity
}
